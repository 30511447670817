<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <Source :source="$t('supervise.interestDetail.sourceTitle')" :isSource="isSource"/>

      <h3>{{ detail.name }}</h3>
      <br />
      <!-- <h4>{{ detail.createTime }} 发布</h4> -->

      <Box :title="$t('supervise.interestDetail.implementation')">
        <div style="width: 100%">
          <!-- <van-cell-group> -->
          <van-cell :title="$t('supervise.interestDetail.implementation')" 
            :value="detail.implementation"
          />
          <van-cell :title="$t('supervise.interestDetail.myend')" 
            :value="detail.myend + $t('supervise.interestDetail.weekdays')"
          />
          <van-cell :title="$t('supervise.interestDetail.faend')" 
            :value="detail.faend + $t('supervise.interestDetail.weekdays')"
          />
          <van-cell
            :title="$t('supervise.interestDetail.ismoney')"
            :value="detail.ismoney === 'N' ? $t('supervise.interestDetail.free') : $t('supervise.interestDetail.charge')"
          />
          <!-- </van-cell-group> -->
          <van-cell :title="$t('casually.feedback')">
            <div slot="default">
              <van-button plain type="danger" size="mini" @click="bindTousu">
                {{ $t('supervise.involvingRightsDetail.complaints') }}
              </van-button>
            </div>
          </van-cell>
        </div>
      </Box>

      <Box :title="$t('supervise.interestDetail.accept')">
        <div>{{ detail.accept }}</div>
        <!-- <div slot="right-icon" style="margin-left: 1rem">
          <img v-show="playStatus!='condition'" class="icon" @click="playControl('condition')" src="../../assets/icon/play.png" />
          <img v-show="playStatus=='condition'" class="icon" @click="playControl()" src="../../assets/icon/play_selected.png" />
        </div> -->
      </Box>

      <Box :title="$t('supervise.interestDetail.process')">
        <!-- <div class="preview-img-box" v-if="detail.picture&&detail.picture.length>0"> -->
        <div
          class="preview-img-box"
          v-if="detail.picture && detail.picture != ''"
        >
          <img class="img-item" alt="" :key="index" :src="item"
            v-for="(item, index) in detail.picture.split(',').slice(0, 3)"
            @click="previewImg(index)"
          />
        </div>
      </Box>

      <audio :src="audioSrc" autoplay style="display: none"></audio>

      <!-- 底部内容 -->
      <Footer />
    </div>

    <Tousu
      :moneyId="moneyId"
      :departId="departId"
      :remark="remark"
      :addsan="addsan"
    />
  </div>
</template>
<script>
import BASE from "../../utils/base";

import Box from "./components/Box";
import Source from "./components/Source";
import Footer from "../../components/Footer";

import Tousu from "./components/Tousu";

import { ImagePreview } from "vant";

export default {
  name: "interest_detail",
  components: { Box, Source, Footer, Tousu },
  activated() {
    this.id = this.$route.query.id;
    this.departId = this.$route.query.departId;
	
	const { isSource='true' } = this.$route.query
    this.isSource = isSource
    
	this.onLoad();
  },
  data() {
    return {
      id: "",
      // 内容详情
      detail: {},
      // 语音链接
      audioSrc: "",
      playStatus: "",

      // 请求参数
      requestData: {
        pageNo: 1,
        pageSize: 20,
      },

      // 投诉弹窗控制
      moneyId: "",
      remark: "",
      addsan: true,
      departId: "",
	  isSource: ''
    };
  },
  methods: {
    onLoad() {
      // 请求参数
      const params = {
        // ...this.requestData,
        id: this.id,
      };

      console.log("*****", this.id);
      this.axios
        .get(BASE.genUrl("zxSupervisePowerlist/list", params))
        .then((res) => {
          let data = res.data;
          if (data.result) {
            // 测试数据
            // data.result.records[0].picture = "../../assets/test/banner_pic3.jpg,../../assets/test/labels.png,../../assets/test/no-data.png,../../assets/test/start_bg.png"
            // console.log(data.result.records[0].picture.split(',').slice(0, 3))
            // 字符串转数组
            // data.result.records[0].picture = data.result.records[0].picture.split(',')
            this.detail = data.result.records[0];
          }
        });
    },
    previewImg(startPosition) {
      const images = this.detail.picture.split(",");
      ImagePreview({ images, startPosition, closeable: true });
    },
    /**
     * 语音
     */
    playControl(index = "") {
      this.audioSrc = "";
      this.playStatus = index;
      if (index !== "") {
        console.log(this.detail[index]);
      }
    },
    bindTousu() {
      this.moneyId = this.detail.id;
      this.$modal.show("modal");
    },
  },
};
</script>
<style scoped>
.container {
  /* height: calc(100vh - 97px); */
}

h3 {
  color: #fff;
  font-size: 16px;
}
h4 {
  color: #fff;
  font-size: 14px;
}

.icon {
  width: 1.33rem;
  margin: 0 5px;
}
.preview-img-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.img-item {
  width: 31%;
  margin: 0.3rem 0;
  height: 6rem;
  border-radius: 1rem;
}
</style>
